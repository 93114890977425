<template>
  <div>
    <div class="container-wrapper">
      <loading v-if="isMounting" />

      <div v-if="isNotMounting" class="d-flex flex-col gap-medium">
        <div
          v-if="isUserActive"
          class="d-flex gap-medium align-center space-between content-wrapper"
        >
          <div class="text-group flex-col d-flex gap-small">
            <strong class="title">Desativar temporariamente</strong>
            <small class="subtitle">
              Ao desativar a sua conta temporariamente, você poderá ativa-la
              novamente a qualquer momento.
            </small>
          </div>

          <custom-button
            class="fitted-btn"
            color="danger"
            text="Desativar conta"
            @click="onOpenDeleteConfirmationModal(DeleteMode.Temporary)"
          />
        </div>

        <div
          v-if="isUserInactive"
          class="d-flex gap-medium align-center space-between content-wrapper"
        >
          <div class="text-group flex-col d-flex gap-small">
            <strong class="title">Reativar conta</strong>
            <small class="subtitle">
              Ao excluir temporariamente, você poderá ativar a sua conta
              novamente a qualquer momento.
            </small>
          </div>

          <custom-button
            :loading="isLoading"
            class="fitted-btn"
            text="Reativar"
            @click="onReactiveAccount()"
          />
        </div>

        <div
          class="d-flex gap-medium align-center space-between content-wrapper"
        >
          <div class="text-group d-flex flex-col gap-small">
            <strong class="title">Excluir permanentemente</strong>
            <small class="subtitle">
              Ao excluir permanentemente, será uma ação irreversível. Todos os
              seus dados serão apagados e você não poderá mais acessar a sua
              conta.
            </small>
          </div>

          <custom-button
            class="fitted-btn"
            color="danger"
            outlined
            text="Excluir conta"
            @click="onOpenDeleteConfirmationModal(DeleteMode.Permanent)"
          />
        </div>
      </div>
    </div>

    <custom-modal
      v-model="isShowingDeleteConfirmationModal"
      class="confirmation-modal"
      @cancel="onCloseDeleteConfirmationModal"
    >
      <div slot="title">
        <strong>Você deseja confirmar esta ação?</strong>
      </div>

      <div slot="content">
        <p v-if="isDeletingTemporarily">
          Ao confirmar, você irá desativar temporariamente a sua conta, podendo
          reativa-la a qualquer momento.
        </p>

        <p v-if="isDeletingPermanently">
          Ao confirmar, você irá excluir permanentemente a sua conta. Essa ação
          será irreversível, logo todos os seus dados serão apagados e você não
          poderá mais acessar a sua conta.
        </p>
      </div>

      <div slot="actions" class="d-flex flex-end gap-medium modal-actions">
        <custom-button
          class="fitted-btn"
          color="danger"
          outlined
          text="Cancelar"
          @click="onCloseDeleteConfirmationModal()"
        />

        <custom-button
          :loading="isLoading"
          class="fitted-btn"
          color="danger"
          text="Confirmar"
          @click="onDeleteAccount()"
        />
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "@/components/Modal/index.vue";
import CustomButton from "@/components/Button/index.vue";
import { DeleteMode } from "@/constants/delete-mode";
import { UserStatus } from "@/constants/user-status.ts";
import UserService from "@/api/services/user.js";
import Loading from "@/components/Loading/Loading.vue";

export default {
  components: {
    Loading,
    CustomButton,
    CustomModal,
  },

  data() {
    return {
      deleteMode: null,
      isLoading: false,
      isMounting: true,
      isShowingDeleteConfirmationModal: false,
      user: null,
    };
  },

  mounted() {
    this.getUserData();
  },

  computed: {
    DeleteMode() {
      return DeleteMode;
    },
    isNotMounting() {
      return !this.isMounting;
    },
    isDeletingPermanently() {
      return this.deleteMode === DeleteMode.Permanent;
    },
    isDeletingTemporarily() {
      return this.deleteMode === DeleteMode.Temporary;
    },
    isUserActive() {
      return this.user?.statusId === UserStatus.Active;
    },
    isUserInactive() {
      return this.user?.statusId === UserStatus.Inactive;
    },
  },

  methods: {
    async getUserData() {
      try {
        const res = await UserService.getCurrentUser();
        this.user = res.data;
        this.isMounting = false;
      } catch (err) {
        console.log("error on get user data", err);
      }
    },
    onOpenDeleteConfirmationModal(mode) {
      this.deleteMode = mode;
      this.isShowingDeleteConfirmationModal = true;
    },
    onCloseDeleteConfirmationModal() {
      if (this.isLoading) return;
      this.deleteMode = null;
      this.isShowingDeleteConfirmationModal = false;
    },
    async onDeleteAccount() {
      this.isLoading = true;

      if (this.deleteMode === DeleteMode.Permanent) {
        await this.onDeleteAccountPermanently();
      }

      if (this.deleteMode === DeleteMode.Temporary) {
        await this.onDeleteAccountTemporarily();
      }

      this.isLoading = false;
    },
    async onDeleteAccountTemporarily() {
      try {
        await UserService.updateUserStatus(this.user.id, UserStatus.Inactive);
        this.deleteMode = null;
        this.isShowingDeleteConfirmationModal = false;
        this.user.statusId = UserStatus.Inactive;
        this.$toast.success("Usuário desativado com sucesso!");
      } catch (error) {
        this.$toast.error("Falha ao desativar o usuário!");
      }
    },
    async onDeleteAccountPermanently() {
      try {
        await UserService.updateUserStatus(this.user.id, UserStatus.Deleted);
        this.$toast.success("Usuário deletado com sucesso");
      } catch (error) {
        this.$toast.error("Falha ao deletar o usuário!");
      }
    },
    async onReactiveAccount() {
      this.isLoading = true;
      try {
        await UserService.updateUserStatus(this.user.id, UserStatus.Active);
        this.$toast.success("Usuário reativado com sucesso");
        this.user.statusId = UserStatus.Active;
      } catch (error) {
        this.$toast.error("Falha ao reativar o usuário!");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container-wrapper {
  background-color: #fafafa;
  border-radius: 4px;
  margin: 1em;
  padding: 2em;
}

.content-wrapper {
  min-width: 200px;
  max-width: 600px;
}

.title {
  font-size: 1rem;
  line-height: 1;
  color: var(--gray-dark);
}

.subtitle {
  font-size: 0.75rem;
  line-height: 1.3;
  color: var(--gray);
}

.flex-col {
  flex-direction: column;
}

.gap-small {
  gap: 0.5em;
}

.gap-medium {
  gap: 1em;
}

.text-group {
  max-width: 400px;
}

.fitted-btn {
  width: fit-content;
  white-space: nowrap;
}

.confirmation-modal ::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-modal ::v-deep .modal-content {
  max-width: 400px;
  padding: 2em;
}

.confirmation-modal ::v-deep .modal__title {
  margin: 0;
  justify-content: flex-start;
}

.confirmation-modal ::v-deep .modal__content {
  margin: 0;
  padding: 1em 0;
  text-align: left;
}

.confirmation-modal ::v-deep .modal__action {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .confirmation-modal ::v-deep .modal-content {
    width: 100%;
    margin: 20px;
  }

  .confirmation-modal ::v-deep .modal__title {
    justify-content: center;
  }

  .confirmation-modal ::v-deep .modal__content {
    text-align: center;
  }

  .modal-actions {
    flex-flow: column-reverse;
    gap: 0;
  }

  .fitted-btn {
    width: 100%;
    white-space: nowrap;
  }
}
</style>
